/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

function seo({
  sitename,
  seoTitle,
  seoDesc,
  image,
  url,
  lang,
  meta,
  bodyClass,
}) {
  
  return (
    <Helmet
      htmlAttributes={{
        lang,
        itemscope: '',
        itemtype: 'http://schema.org/Article'
      }}
      bodyAttributes={{
        class: bodyClass
      }}
      title={seoTitle}
      titleTemplate={sitename ? `%s | ${sitename}` : seoTitle}
      meta={[
        {
          itemprop: `name`,
          content: seoTitle,
        },
        {
          name: `description`,
          content: seoDesc,
        },
        {
          itemprop: `description`,
          content: seoDesc,
        },
        image ? {
          itemprop: `image`,
          content: `${image}`,
        } : {},
        {
          property: `og:title`,
          content: seoTitle,
        },
        seoDesc ? {
          property: `og:description`,
          content: seoDesc,
        } : {},
        {
          property: `og:type`,
          content: `article`,
        },
        {
          property: `og:url`,
          content: url,
        },
        image ? {
          property: `og:image`,
          content: `${image}`,
        } : {},
        {
          property: `og:site_name`,
          content: sitename,
        },
      ].concat(meta)}
    />
  )
}

seo.defaultProps = {
  layout: false,
  sitename: 'Asuntomessut',
  lang: 'fi',
  meta: [],
  image: '',
  published: '',
  modified: '',
  pageTemplate: '',
  date: '',
  pageIntention: '',
  customerJourneyPhases: '',
  businessAreas: '',
}

seo.propTypes = {
  sitename: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDesc: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  lang: PropTypes.string,
  published: PropTypes.string,
  modified: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default seo;
